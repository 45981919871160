@import "../../assets/fonts/IBMPlexMono/IBMPlexMono-Bold.css";
@import "../../assets/fonts/IBMPlexMono/IBMPlexMono-BoldItalic.css";
@import "../../assets/fonts/IBMPlexMono/IBMPlexMono-ExtraLight.css";
@import "../../assets/fonts/IBMPlexMono/IBMPlexMono-ExtraLightItalic.css";
@import "../../assets/fonts/IBMPlexMono/IBMPlexMono-Italic.css";
@import "../../assets/fonts/IBMPlexMono/IBMPlexMono-Light.css";
@import "../../assets/fonts/IBMPlexMono/IBMPlexMono-LightItalic.css";
@import "../../assets/fonts/IBMPlexMono/IBMPlexMono-Medium.css";
@import "../../assets/fonts/IBMPlexMono/IBMPlexMono-MediumItalic.css";
@import "../../assets/fonts/IBMPlexMono/IBMPlexMono-Regular.css";
@import "../../assets/fonts/IBMPlexMono/IBMPlexMono-SemiBold.css";
@import "../../assets/fonts/IBMPlexMono/IBMPlexMono-SemiBoldItalic.css";
@import "../../assets/fonts/IBMPlexMono/IBMPlexMono-Text.css";
@import "../../assets/fonts/IBMPlexMono/IBMPlexMono-TextItalic.css";
@import "../../assets/fonts/IBMPlexMono/IBMPlexMono-Thin.css";
@import "../../assets/fonts/IBMPlexMono/IBMPlexMono-ThinItalic.css";

@font-face {
  font-family: Inter;
  font-weight: 100 900;
  font-display: swap;
  font-feature-settings: "cpsp", "ss01", "ss04", "tnum" 0;
  src: url("../../assets/fonts/Inter/Inter.var.woff2") format("woff2");
}

input::-ms-clear {
  display: none;
}

input::-ms-reveal {
  display: none;
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  margin: 0;
}

[tabindex="-1"]:focus {
  outline: none;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5em;
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

abbr[title], abbr[data-original-title] {
  -webkit-text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1em;
}

input[type="text"], input[type="password"], input[type="number"], textarea {
  -webkit-appearance: none;
}

ol, ul {
  margin-top: 0;
  margin-bottom: 1em;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

blockquote {
  margin: 0 0 1em;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

pre, code, kbd, samp {
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}

figure {
  margin: 0 0 1em;
}

img {
  vertical-align: middle;
  border-style: none;
}

a, area, button, [role="button"], input:not([type="range"]), label, select, summary, textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  text-align: left;
  caption-side: bottom;
  padding-top: .75em;
  padding-bottom: .3em;
}

input, button, select, optgroup, textarea {
  color: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  font-family: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
  margin-bottom: .5em;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

mark {
  background-color: #fff8f1;
  padding: .2em;
}

dl, dt, dd {
  all: unset;
  display: revert;
}

:root {
  --font-feature-settings-inter: "cpsp", "ss01", "ss04", "tnum" 0;
  --font-feature-settings-plex-mono: normal;
}

html, body, #app {
  width: 100%;
  height: 100%;
}

body {
  font-feature-settings: var(--font-feature-settings-inter);
  letter-spacing: .03em;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: #0000;
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 1.5715;
}

@media (prefers-color-scheme: dark) {
  body:not([data-theme="light"]) {
    background: #000;
  }
}

body[data-theme="dark"] {
  background: #000;
}

pre, code, kbd, samp {
  font-feature-settings: var(--font-feature-settings-plex-mono);
  letter-spacing: normal;
  font-family: IBM Plex Mono, Monaco, Menlo, Ubuntu Mono, Consolas, monospace;
}

button {
  font-feature-settings: var(--font-feature-settings-inter);
}

.xterm {
  letter-spacing: normal;
}

.ant-menu-inline-collapsed .ant-menu-item-icon + .ant-badge {
  max-width: none;
  margin-left: 4px;
  opacity: 1 !important;
}

.ant-typography .ant-typography-copy {
  color: inherit;
}

.ant-tag > span:not(:empty) + span:not(:empty), .ant-tag > svg + span:not(:empty), .ant-tag > span:not(:empty) + svg, .ant-tag > svg + svg {
  margin-left: 8px;
}

.ant-tag .ant-typography {
  color: inherit;
}

.ant-btn {
  gap: 4px;
}

.ant-menu-item svg:first-child, .ant-tooltip-inner svg:first-child, .ant-menu-submenu svg:first-child {
  margin-right: 4px;
  margin-left: 0 !important;
}

.ant-menu-item svg:last-child, .ant-tooltip-inner svg:last-child, .ant-menu-submenu svg:last-child {
  margin-left: 4px;
  margin-right: 0 !important;
}

.ant-tabs-tab svg {
  margin-right: 4px;
}

.ant-menu-inline-collapsed > .ant-menu-item .svg-inline--fa ~ span:not(.ant-badge), .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .svg-inline--fa ~ span:not(.ant-badge), .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .svg-inline--fa ~ span:not(.ant-badge), .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .svg-inline--fa ~ span:not(.ant-badge), .ant-menu-inline-collapsed > .ant-menu-item .svg-inline--fa ~ a, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .svg-inline--fa ~ a, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .svg-inline--fa ~ a, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .svg-inline--fa ~ a {
  opacity: 0;
  max-width: 0;
  display: inline-block;
}

.ant-menu-item .svg-inline--fa ~ span:not(.ant-badge), .ant-menu-submenu-title .svg-inline--fa ~ span:not(.ant-badge), .ant-menu-item .svg-inline--fa ~ a, .ant-menu-submenu-title .svg-inline--fa ~ a {
  opacity: 1;
  transition: opacity .3s cubic-bezier(.645, .045, .355, 1), width .3s cubic-bezier(.645, .045, .355, 1);
}
