#loading {
  height: 100vh;
  display: grid;
  place-content: center;
  font-family: Inter, sans-serif;
}
#loading > div {
  color: #7e7a71;
  font-size: 1.2rem;
}
#loading noscript div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 1em;
  background: #ff584c;
  color: white;
  text-align: center;
}
