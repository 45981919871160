@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Mono Light Italic), local(IBMPlexMono-LightItalic), url("IBMPlexMono-LightItalic-Cyrillic.woff2") format("woff2");
  unicode-range: U+400-45F, U+472-473, U+490-49D, U+4A0-4A5, U+4AA-4AB, U+4AE-4B3, U+4B6-4BB, U+4C0-4C2, U+4CF-4D9, U+4DC-4DF, U+4E2-4E9, U+4EE-4F5, U+4F8-4F9;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Mono Light Italic), local(IBMPlexMono-LightItalic), url("IBMPlexMono-LightItalic-Latin1.woff2") format("woff2");
  unicode-range: U+0, U+D, U+20-7E, U+A0-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+20AC, U+2122, U+2212, U+FB01-FB02;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Mono Light Italic), local(IBMPlexMono-LightItalic), url("IBMPlexMono-LightItalic-Latin2.woff2") format("woff2");
  unicode-range: U+100-101, U+104-130, U+132-151, U+154-17F, U+18F, U+192, U+1A0-1A1, U+1AF-1B0, U+1FA-1FF, U+218-21B, U+237, U+259, U+1E80-1E85, U+1E9E, U+20A1, U+20A4, U+20A6, U+20A8-20AA, U+20AD-20AE, U+20B1-20B2, U+20B4-20B5, U+20B8-20BA, U+20BD, U+20BF;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Mono Light Italic), local(IBMPlexMono-LightItalic), url("IBMPlexMono-LightItalic-Latin3.woff2") format("woff2");
  unicode-range: U+102-103, U+1CD-1DC, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: italic;
  font-weight: 300;
  src: local(IBM Plex Mono Light Italic), local(IBMPlexMono-LightItalic), url("IBMPlexMono-LightItalic-Pi.woff2") format("woff2");
  unicode-range: U+3C0, U+E3F, U+2032-2033, U+2070, U+2074-2079, U+2080-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+2215, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+2500-259F, U+25CA, U+2713, U+274C, U+2B0E-2B11;
}
